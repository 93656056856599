body {
    background-color: #f5f7f8 !important;
}
/* .link-white a, a:hover, a:active, a:visited {
    color: #fff !important;
} */
@media screen and (max-width: 768px) {
    .container-layout {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.bg-motif-blue{
    background: #3F51B5;
    background: -webkit-linear-gradient(to right, #3F51B5 42%, #3849A6 84%);
    background: -moz-linear-gradient(to right, #3F51B5 42%, #3849A6 84%);
    background: linear-gradient(to right, #3F51B5 42%, #3849A6 84%);
   
    color: white !important;
}


/* .bg-motif-blue{
    background: -webkit-linear-gradient(left, #3f51b5 20px, transparent 1%) center, -webkit-linear-gradient(#3f51b5 20px, transparent 1%) center, #9192FB;
    background: linear-gradient(90deg, #3f51b5 20px, transparent 1%) center, linear-gradient(#3f51b5 20px, transparent 1%) center, #9192FB;
    background-size: 22px 22px;
    color: white !important;
} */


a.link-defaullt,  a:hover.link-defaullt, a:active.link-defaullt, a:visited.link-defaullt {
    color: rgba(0, 0, 0, 0.87) !important;
}